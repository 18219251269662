import React, { Suspense, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import DeleteSalonModal from "../../component/Common/Modals/DeleteSalonModal";
import { toastifyMsg } from "../../helpers/commonFunction";
import { backBtnArrow, deleteIcon } from "../../icons";
import {
  useGetSalonByIdQuery,
  useUpdateSalonMutation,
} from "../../store/salon/salonSlice";

const SalonDetail = React.lazy(() =>
  import("../../component/Common/PageComponent/SalonComponent/SalonDetail")
);

const ViewSalon = () => {
  const navigate = useNavigate();
  const params = useParams();
  console.log("params", params);

  const [deleteSalonModal, setDeleteSalonModal] = useState(false);

  const {
    data: salon,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useGetSalonByIdQuery({ salon_id: params.salon_id, user_id: params.id });

  let salonD;
  if (isError) {
    toastifyMsg("error", error?.data?.message);
  } else if (isSuccess) {
    salonD = salon;
  }

  const [
    deleteSalon,
    {
      isLoading: deleteLoading,
      error: deleteError,
      isError: deleteIsError,
      isSuccess: deletedSalon,
    },
  ] = useUpdateSalonMutation();

  console.log("salonD", salonD);

  const hideModal = () => {
    setDeleteSalonModal(false);
  };

  const ShowDeleteSalonModal = () => {
    setDeleteSalonModal(true);
  };

  const deleteSalonHandler = () => {
    const data = {
      delete_status: "1",
    };
    deleteSalon({ data, id: salonD?.id });
  };

  // Delete Salon
  useEffect(() => {
    if (deleteIsError) {
      toastifyMsg("error", deleteError?.data?.message);
    } else if (deletedSalon) {
      toastifyMsg("success", "Salon has been Deleted!");
      navigate(-1);
      setDeleteSalonModal(false);
    }
  }, [deleteError, deleteIsError, deletedSalon]);
  //

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading={"SALON DETAILS"} />
        {isLoading || deleteLoading ? (
          <Loader />
        ) : (
          <>
            <div className="project_edit_main_link">
              <div className="comman_center_btn_container">
                <button className="go_back_btn" onClick={() => navigate(-1)}>
                  {backBtnArrow} Back
                </button>
                <div className="project_extra_btn_head">
                  <Link
                    className="comman_btn extra_btn_row"
                    to={`/user/${params.id}/salon-licence/${params.salon_id}/page/1`}
                  >
                    License Management
                  </Link>
                  <Link
                    className="comman_btn extra_btn_row"
                    to={`/user/${params.id}/salon-booking/${params.salon_id}/page/1`}
                  >
                    View Bookings
                  </Link>
                  <Link
                    className="comman_btn extra_btn_row"
                    to={`/user/${params.id}/salon-message-record/${params.salon_id}/page/1`}
                  >
                    Message Management
                  </Link>
                  <div className="extra_btn_delete_icon_div">
                    <button
                      aria-label="delete"
                      className="extra_btn_delete_icon"
                      onClick={() => ShowDeleteSalonModal(salon.id)}
                    >
                      {deleteIcon}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Suspense fallback={<Loader />}>
              <SalonDetail salonD={salonD} />
            </Suspense>
          </>
        )}
      </div>
      <Modal
        className="modal_main_component modal_background_content"
        show={deleteSalonModal}
        onHide={hideModal}
        centered
      >
        <DeleteSalonModal
          hideModal={hideModal}
          deleteSalonHandler={deleteSalonHandler}
        />
      </Modal>
    </>
  );
};

export default ViewSalon;
