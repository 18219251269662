import moment from "moment";

// Coupon TABLE HEAD LIST
export const tableHeadItemsForCoupon = [
  {
    id: 1,
    name: "Coupon Status",
  },
  {
    id: 2,
    name: "Coupon Code",
  },
  {
    id: 3,
    name: "Coupon Availability",
  },
  {
    id: 4,
    name: "License Period",
  },
  {
    id: 5,
    name: "Coupon Expiry Date",
  },
  {
    id: 6,
    name: "Actions",
  },
];
//

// COUPON FORM VALIDATIIONS
export const CouponFormValidate = (
  fieldValues,
  temp,
  setValues,
  values,
  setErrors,
  { forAll, minimumAvaibility }
) => {
  console.log("forAllINCOMPONENT", forAll);
  console.log("minimumAvaibilityyyyyy", minimumAvaibility);

  if (forAll) {
    if ("total_availability" in fieldValues) {
      temp.total_availability = fieldValues.total_availability
        ? +fieldValues.total_availability > 0
          ? ""
          : "Please Enter valid number of user!"
        : "Please Enter number of users!";
    }
    if (fieldValues.total_availability !== "" && minimumAvaibility) {
      if ("total_availability" in fieldValues) {
        temp.total_availability =
          minimumAvaibility <= +fieldValues.total_availability
            ? ""
            : `Value should be greater than ${minimumAvaibility - 1} !`;
      }
    }
  }
  if ("licence_period_in_days" in fieldValues) {
    temp.licence_period_in_days = fieldValues.licence_period_in_days
      ? +fieldValues.licence_period_in_days > 0
        ? ""
        : "Please Enter valid number of days!"
      : "Please Enter number of days!";
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
//

// Create coupon for specific Salon Validation
export const specificSalonValidation = (values, setValues, userId, salonId) => {
  let isValid = false;
  if (!userId) {
    setValues({
      ...values,
      userId_err: "Please Select User!",
    });
  } else {
    if (!salonId) {
      setValues({
        ...values,
        salonId_err: "Please Select Salon!",
      });
    } else {
      isValid = true;
    }
  }
  return isValid;
};
//

export const expiryDateValidation = (values, setValues) => {
  let isExpiryDate = false;
  if (!values.expiry_date) {
    setValues({
      ...values,
      expiry_date_err: "Coupon Expiy Date is required!",
    });
  } else {
    isExpiryDate = true;
  }
  return isExpiryDate;
};

// new date from old Date
export const newExpiryFromOldExpiryInCoupon = (lastExpiryDate, days) => {
  var dateString = moment(lastExpiryDate).format().split("T")[0];
  console.log("lastExpiryDate", lastExpiryDate);

  const totalDays = +days;
  console.log("totalDays123", totalDays);

  var newExpiryDate = new Date(dateString); // Date before ADD
  newExpiryDate.setDate(newExpiryDate.getDate() + totalDays); // Set now + 30 days as the new date
  console.log("newExpiryDate123", newExpiryDate);

  const newExpiryDateString = moment(newExpiryDate).format().split("T")[0];
  console.log("newExpiryDateString123", newExpiryDateString);

  return newExpiryDateString;
};

//// new EpiryDate from current Date
export const someDaysAfterDate = (days) => {
  // get expiry date string
  const currentDate = Date();
  console.log("currentDate", currentDate);
  var dateString = moment(currentDate).format().split("T")[0];
  console.log("dateString", dateString);
  //

  const totalDays = +days;
  console.log("days", days);

  var newExpiryDate = new Date(dateString); // Date before ADD
  newExpiryDate.setDate(newExpiryDate.getDate() + totalDays); // Set now + 30 days as the new date
  console.log("newExpiryDate", newExpiryDate);

  // const newExpiryDateString = moment(newExpiryDate).format().split("T")[0];
  // console.log("newExpiryDateString", newExpiryDateString);

  return newExpiryDate;
};
