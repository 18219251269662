import React from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";

import Button from "../../Button";
import Input from "../../Input";

const CouponPageForm = ({
  userOptions,
  salonOptions,
  values,
  handleInputChange,
  userId,
  userIdHandler,
  salonId,
  salonIdHandler,
  createCouponHandler,
  errors,
  forAll,
  changeTabHandler,
  couponDetail,
  updateCouponHandler,
  setValues,
}) => {
  console.log("userId098765", userId);

  return (
    <>
      {" "}
      <div className="create_from_row">
        <div className="tab" onChange={changeTabHandler} value={forAll}>
          <input
            label="For All"
            type="radio"
            id="CREDITED"
            name="forAll"
            value={true}
            defaultChecked={forAll}
            disabled={couponDetail && couponDetail.salon_owner_id}
          />
          <input
            label="For Specific Salon"
            type="radio"
            id="DEBITED"
            name="forAll"
            value={false}
            defaultChecked={!forAll}
            disabled={couponDetail && !couponDetail.salon_owner_id}
          />
        </div>
        <div></div>
        {forAll ? (
          <Input
            className="project_edit_feature_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter amount of user for coupon Availability"
            label="Coupon Available For"
            id="total_availability"
            name="total_availability"
            onKeyPress={(e) => !/^\d*\.?\d*$/.test(e.key) && e.preventDefault()}
            value={values.total_availability}
            onChange={handleInputChange}
            error={errors?.total_availability || ""}
          />
        ) : (
          !couponDetail && (
            <>
              <div className="project_edit_feature_input">
                <h4 className="project_text_area_label">Select User</h4>

                <Select
                  classNamePrefix="project_common_select_input"
                  errorClassName="err_text"
                  type="text"
                  placeholder="Search..."
                  label="User Name"
                  id="user_name"
                  name="user_name"
                  isClearable={true}
                  isSearchable={true}
                  onChange={(e) => userIdHandler(e)}
                  value={userId}
                  //   error={errors?.salon_name || ""}
                  options={userOptions}
                />
                {values.userId_err && (
                  <span className="err_text">{values.userId_err}</span>
                )}
              </div>
              {userId ? (
                <div className="project_edit_feature_input">
                  <h4 className="project_text_area_label">Select Salon</h4>

                  <Select
                    classNamePrefix="project_common_select_input"
                    errorClassName="err_text"
                    type="text"
                    placeholder="Search..."
                    label="Salon Name"
                    id="salon_name"
                    name="salon_name"
                    isClearable={true}
                    isSearchable={true}
                    onChange={(e) => salonIdHandler(e)}
                    value={salonId}
                    //   error={errors?.salon_name || ""}
                    options={salonOptions}
                  />
                  {values.salonId_err && (
                    <span className="err_text">{values.salonId_err}</span>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </>
          )
        )}
        <div className="project_edit_feature_input">
          <h4 className="project_text_area_label">Coupon Expiry Date</h4>
          <div className="custom_date_picker">
            <DatePicker
              id="expiry_date"
              className="zu_common_form_input"
              placeholderText="MM/DD/YYYY"
              dateFormat="dd/MM/yyyy"
              name="expiry_date"
              selected={values.expiry_date}
              dateFormatCalendar={"MMM yyyy"}
              onChange={(date) => {
                setValues({
                  ...values,
                  expiry_date: date,
                  expiry_date_err: "",
                });
              }}
              minDate={moment().add(1, "day").toDate()}
            />
          </div>
          {values?.expiry_date_err && (
            <span className="err_text">{values.expiry_date_err}</span>
          )}
        </div>
        <Input
          className="project_edit_feature_input"
          errorClassName="err_text"
          type="text"
          placeholder="Describe license period in days"
          label="License Period (Days)"
          id="licence_period_in_days"
          name="licence_period_in_days"
          value={values.licence_period_in_days}
          onChange={handleInputChange}
          error={errors?.licence_period_in_days || ""}
          onKeyPress={(e) => !/^\d*\.?\d*$/.test(e.key) && e.preventDefault()}
        />
      </div>
      <Button
        className="project_submit_bottom_btn center_back_btn"
        buttonClassName="comman_btn"
        onClick={couponDetail ? updateCouponHandler : createCouponHandler}
        text={couponDetail ? "Update" : "Create"}
      />
    </>
  );
};

export default CouponPageForm;
