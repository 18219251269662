import React from "react";
import Input from "../Input";

const UpdateLicenceModal = ({
  hideModal,
  values,
  decreaseDateHandler,
  increaseDateHandler,
  setValues,
  createUpdateLicenceHandler,
  handleInputChange,
  errors,
  licenceId,
}) => {
  console.log("values.licence_payment", values.licence_payment);
  return (
    <div className="modal_container">
      <div className="modal_header_container">
        <div className="modal_heading">
          <h1>{licenceId ? "Update License" : "Create License"}</h1>
        </div>
        <div className="modal_corner_close_btn">
          <button onClick={() => hideModal()}>X</button>
        </div>
      </div>
      <Input
        className="modal_input_field"
        errorClassName="err_text"
        type="number"
        placeholder="Enter Amount"
        label="License Payment"
        id="licence_payment"
        name="licence_payment"
        value={values.licence_payment}
        onChange={handleInputChange}
        error={errors?.licence_payment || ""}
      />
      <div className="modal_paragraph modal_p_for_licence">
        <p>Select month you want to add in License</p>
      </div>
      <div className="project_edit_feature_input">
        <div className="">
          <div className="quantity">
            <div className="quantity_row">
              <button
                className="quantity__minus"
                onClick={decreaseDateHandler}
                disabled={values.new_expiry_month === 1}
              >
                -
              </button>
              <input
                disabled
                name="quantity"
                type="number"
                className="quantity__input"
                value={values.new_expiry_month}
                min="0"
                onChange={(e) => {
                  setValues({
                    ...values,
                    new_expiry_month: +e.target.value,
                  });
                }}
              />
              <button className="quantity__plus" onClick={increaseDateHandler}>
                +
              </button>
            </div>
            <p className="quantity_desc">Months</p>
          </div>
        </div>
      </div>

      <div className="modal_btn_row modal_btn_row_top_margin">
        <button
          onClick={() => hideModal()}
          className="modal_btn modal_cancel_btn"
        >
          Cancel
        </button>
        <button
          className="modal_btn modal_create_btn"
          onClick={createUpdateLicenceHandler}
        >
          {licenceId ? "Update" : "Create"}
        </button>
      </div>
    </div>
  );
};

export default UpdateLicenceModal;
