import React from "react";

const UpdateCategoryIconModal = ({ hideModal, submitHandler, listIconCategory, icon }) => {
  const [category, setCategory] = React.useState(icon.category_id);
  return (
    <div className="modal_container">
      <div className="modal_header_container">
        <div className="modal_heading">
          <h3>Update Icon Category</h3>
        </div>
        <div className="modal_corner_close_btn">
          <button onClick={() => hideModal()}>X</button>
        </div>
      </div>
      <div className="w-100">
        <div className="form-group">
          <label>Icon Category</label>
          <select
            className="form-control"
            defaultValue={icon.category_id}
            onChange={event => {
              setCategory(event.target.value);
            }}
          >
            {listIconCategory.map((item) => (
              <option key={item.id} value={item.id}>{item.name}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="modal_btn_row">
        <button
          onClick={() => hideModal()}
          className="modal_btn modal_cancel_btn"
        >
          Cancel
        </button>
        <button
          className="modal_btn modal_update_btn"
          onClick={() => submitHandler({
            category_id: category
          })}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default UpdateCategoryIconModal;
