import { apiSlice } from "../api/apiSlice";
import { authHeaders } from "../../helpers/axios";

export const serviceIconSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServiceIcons: builder.query({
      query: () => {
        return {
          url: `/smart-beauty/salon/get-service-icons`,
          method: "GET",
          headers: authHeaders(),
        };
      },
      providesTags: ["Services"],
    }),

    addServiceIcons: builder.mutation({
      query: (data) => {
        return {
          url: `/smart-beauty/salon/add-service-icon`,
          method: "POST",
          headers: authHeaders(),
          body: data,
        };
      },
      invalidatesTags: ["Services"],
    }),

    deleteServiceIcon: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/smart-beauty/salon/update-service-icon/${id}`,
          method: "PUT",
          headers: authHeaders(),
          body: data,
        };
      },
      invalidatesTags: ["Services"],
    }),

    listIconCategory: builder.query({
      query: () => {
        return {
          url: '/smart-beauty/salon/get-service-icon-image-category',
          method: 'GET',
          headers: authHeaders(),
        }
      },
      providesTags: ["ServiceIcons"],
    }),

    updateServiceIcon: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/smart-beauty/salon/update-service-icon/${id}`,
          method: "PUT",
          headers: authHeaders(),
          body: data,
        };
      },
      invalidatesTags: ["Services"],
    }),
  }),
});

export const {
  useGetServiceIconsQuery,
  useAddServiceIconsMutation,
  useDeleteServiceIconMutation,
  useListIconCategoryQuery,
  useUpdateServiceIconMutation,
} = serviceIconSlice;
